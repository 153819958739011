"use strict";

import React, { Component }                       from "react";
import { connect }                                from "react-redux";
import { passwordComplexityRegex }                from "constants/regex";
import {Elements}                                 from 'react-stripe-elements';
import InjectedCheckoutForm                       from 'elements/CheckoutForm';
import InjectedPaymentMethodForm                  from 'elements/UpdatePaymentMethod';
import InjectedCancelSubscription                 from 'elements/CancelSubscription';
import ShieldLogo                                 from 'elements/ShieldLogo';
import FloProtectActions                          from 'actions/floprotect';
import LocationProfileActions                     from 'actions/locationProfile';
import {Helmet}                                   from "react-helmet";
import queryString                                from 'query-string';
import AlertsPanel                                from "elements/AlertsPanel";
import PropTypes                                  from 'prop-types';
import { Popover, PopoverBody, Modal, ModalBody } from 'reactstrap';
import {browserHistory}                           from "react-router";
import User                                       from "../../actions/user";
import { Alert }                                  from 'reactstrap';
import moment                                     from 'moment';

export class FloProtect extends Component {

  constructor(props, context) {
    super(props, context);

		this.state = {
      showCheckoutModal: false,
      showCancelFloProtectModal: false,
      popoverOpenProactiveMonitoringInfo: false,
      popoverOpenDeducibleGuaranteeInfo: false,
      showSubscriptionEndingAlert: true,
		};
  }

  componentDidMount() {
    const mixpanel = this.context.mixpanel;
    const queryParams = queryString.parse(this.props.location.search);

    if (queryParams.location) {
      User.updateNextLocation(queryParams.location);
    }

    mixpanel.track(
      "Viewed User Portal Subscription"
    );
    FloProtectActions.hydrateStore(queryParams.plan_id || 'default', queryParams.source_id, queryParams.coupon_id);
  }

  subscribe = stripe => event => {
    if (event) {
      event.preventDefault();
    }

    const mixpanel = this.context.mixpanel;
    const queryParams = queryString.parse(this.props.location.search);

    const subscriptionPromise = (stripe) ?
      FloProtectActions.subscribe(stripe, this.props.user.id, this.props.plan.plan_id, this.props.sourceId, this.props.couponInfo.id)
      :
      FloProtectActions.subscribeUsingCreditCardOnFile(this.props.user.id, this.props.plan.plan_id, this.props.sourceId, this.props.couponInfo.id);

    subscriptionPromise.then(() => {
      mixpanel.track(
        "Subscription Successful",
        {
          "Subscription_Source": queryParams.source_id || 'user-portal',
          "Plan_ID": queryParams.plan_id || 'default'
        }
      );
    });
  }

  updatePaymentMethod = stripe => env => {
    env.preventDefault();
    FloProtectActions.updatePaymentMethod(stripe, this.props.user.id)
  }

  handleCancelSubscription = values => {
    FloProtectActions.cancelSubscription(this.props.subscriptionId, values.reason);
  }

  handleCloseCheckoutModal = () => {
    const queryParams = queryString.parse(this.props.location.search);

    this.setState({ showCheckoutModal: false });
    FloProtectActions.clearErrorMessage();
    FloProtectActions.cleanPaymentFormState();

    if(queryParams.source_id === 'insurance_letter') {
      browserHistory.push('/insurance-letter');
    }
  }

  handleCloseUpdatePaymentMethodModal = () => {
    FloProtectActions.updatePaymentMethodFormInfo({ isModalOpen: false });
    FloProtectActions.clearErrorMessage();
    FloProtectActions.cleanPaymentFormState();
  }

  openChangePaymentMethod = () => {
    FloProtectActions.updatePaymentMethodFormInfo({ isModalOpen: true });
    FloProtectActions.loadPaymentInfo(this.props.user.id);
  }

  handleShowCheckoutModal = () => {
    const mixpanel = this.context.mixpanel;
    const queryParams = queryString.parse(this.props.location.search);
    FloProtectActions.loadPaymentInfo(this.props.user.id);
    mixpanel.track(
      "Clicked Subscribe",
      {
        "Subscription_Source": queryParams.source_id || 'user-portal',
        "Plan_ID": queryParams.plan_id || 'default'
      }
    );
    this.setState({showCheckoutModal: true});
  }

  handleCloseCancelFloProtectModal = () => {
    this.setState({ showCancelFloProtectModal: false });
    FloProtectActions.cleanPaymentFormState();
  }

  handleShowCancelFloProtectModal = () => {
    this.setState({showCancelFloProtectModal: true});
  }

  toggleProactiveMonitoringInfo = () => {
    this.setState({
      popoverOpenProactiveMonitoringInfo: !this.state.popoverOpenProactiveMonitoringInfo
    });
  }

  toggleDeducibleGuaranteeInfo = () => {
    this.setState({
      popoverOpenDeducibleGuaranteeInfo: !this.state.popoverOpenDeducibleGuaranteeInfo
    });
  }

  /**
   * React Lifecycle: render - Is fired when the <Settings_UserProfile /> component is leveraged to draw to the screen.
   */
  render() {
    const floProtectEnabled = this.props.floProtect.enabled;
    const { cancelAtPeriodEnd, status, currentPeriodEnd } = this.props;
    const isSubscriptionActive = floProtectEnabled && !cancelAtPeriodEnd;

    const basicPlanButton =
      (<button className="btn btn-lg btn-subscribe" onClick={this.handleShowCancelFloProtectModal} disabled={!isSubscriptionActive}>
        <div>
          {floProtectEnabled && <span>Downgrade</span>}
          {!floProtectEnabled && <span><i className="fas fa-check"></i>  Current Plan</span>}
        </div>
      </button>);

    const isDelinquent = !floProtectEnabled && status === 'delinquent';

    let floProtectPlanButton = null;

    const floProtectButton =
      (<button className="btn btn-lg btn-subscribe btn-subscribe-pro" onClick={this.handleShowCheckoutModal} disabled={isSubscriptionActive || !this.props.user.id}>
        {isSubscriptionActive && <span ><i className="fas fa-check"></i>  Current Plan</span>}
        {!isSubscriptionActive && <span>Activate FloProtect Plan</span>}
      </button>);

    const floProtectDelinquentButton =
      (<button className="btn btn-lg btn-subscribe btn-subscribe-delinquent" onClick={this.openChangePaymentMethod} disabled={!this.props.user.id}>
          <span>Update Payment</span>
      </button>);

    floProtectPlanButton = isDelinquent ? floProtectDelinquentButton : floProtectButton;
    return (
      <div className="container home-protect">
        <Helmet>
          <title>Flo - FloProtect</title>
        </Helmet>

        <Alert
            color="danger" isOpen={!!(this.state.showSubscriptionEndingAlert && floProtectEnabled && cancelAtPeriodEnd)}
            toggle={() => this.setState({ showSubscriptionEndingAlert: false })}
            fade={false}
          >
            <span className="link-unstyled">
              Your subscription has been canceled and you will not be charged further, however it will remain active until the end of the billing period
              {currentPeriodEnd ? ` (${moment(currentPeriodEnd).format('LL')}).` : '.'}
            </span>
        </Alert>

        { this.props.couponInfo.name &&
          <div className="row">
            <div className="col-12">
              <div className="alert alert-info" role="alert">
                { "Coupon applied: " + this.props.couponInfo.name + " - " + this.props.couponInfo.percent_off + "% off " }
              </div>
            </div>
          </div>
        }

        <div className="card home-protect-content">
          <div className="card-body">
            <h1>A Guarantee Against the Unexpected</h1>

            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 short-description">
                <h3 className="text-center">
                  An unrivaled layer of security with on-demand expert advice and financial protection against the worst events. <br/>
                  Only ${this.props.plan.monthly_cost}/month. Billed month-to-month. Cancel anytime.
                </h3>
              </div>
            </div>

            <div className="row justify-content-center attached">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 order-md-12">
                <div className="wrapper">
                  <div className="card pricing pro details">
                    <div className="card-header plan pro-plan">
                      <div className="row justify-content-center">
                        <div className="col-12 pricing-title">
                          <div className="d-inline">
                            <ShieldLogo isActive={false} size="big"/>
                          </div>
                          <div className="d-inline align-middle">
                            FloProtect Plan
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="description">
                        Add an additional layer of protection to your home
                      </div>
                      <div>
                        {floProtectPlanButton}
                      </div>
                      <div className="plan-details">
                        <div className="additional-info">In addition to Standard Protection</div>
                        <ul>
                          <li>We-Pay $5,000 Deductible Guarantee</li>
                          <li>5-year Extended Warranty</li>
                          <li>LIVE Smart Water Assistant</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 order-md-1">
                <div className="wrapper">
                  <div className="card pricing basic details">
                    <div className="card-header plan basic-plan">
                      Standard Protection
                    </div>
                    <div className="card-body">
                      <div className="description">
                        Standard protection and support for your home
                      </div>
                      <div>
                        {basicPlanButton}
                      </div>
                      <div className="plan-details">
                        <ul>
                          <li>Leak Detection and Prevention</li>
                          <li>Email & Phone Alerts</li>
                          <li>Automatic Water Shut-Off</li>
                          <li>You pay any insurance deductibles</li>
                          <li>Basic 1 Year Product Warranty</li>
                          <li>Ticket-Based Support</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 press-logos">
                <img src="/assets/images/press-logos@2x.png" className="img-fluid" />
              </div>
            </div>

            <div className="row flo-protect-banner">
              <div className="col-12">
                <div className="row">
                  <div className="col-2 image-wrapper">
                    <img src="/assets/images/flo-protect-banner-we-pay.png" className="img-fluid" />
                  </div>
                  <div className="col-10 banner-details">
                    <div><h5>We-Pay $5,000 Deductible Guarantee</h5></div>
                    <div><span>Even a little water in your home can become a costly headache. With our guarantee, you can rest even easier knowing that you will be protected from financial responsibility should you find yourself in an unfortunate water damage situation.</span></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2 image-wrapper">
                    <img src="/assets/images/flo-protect-banner-warranty.png" className="img-fluid" />
                  </div>
                  <div className="col-10 banner-details">
                    <div><h5>5-year Extended Warranty</h5></div>
                    <div><span>Extend your smart water warranty to an industry-best 5-years. Regardless of climate, installation location, or use case, you'll get hassle-free product swaps and part replacements backed by the Moen name for 4 more years.</span></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2 image-wrapper">
                    <img src="/assets/images/flo-protect-banner-chat.png" className="img-fluid" />
                  </div>
                  <div className="col-10 banner-details">
                    <div><h5>Live Smart Water Assistant</h5></div>
                    <div><span>Unlock 5-start plumbing assistance with a new chat feature directly in your Flo by Moen app. Diagnose alerts in real-time, troubleshoot your unique home setup, and get expert advice on plumbing maintenance from our team of experts.</span></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row chat-with-us">
              <div className="col-md-12">
                <div className="content text-center">
                  <h3>Chat with our team activating your FloProtect Plan</h3>
                  <p>
                    Have specific questions? Chat our team using the chat on the bottom right.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>

        <Modal
          isOpen={this.state.showCheckoutModal}
          toggle={this.handleCloseCheckoutModal}
        >
          <ModalBody>
            <Elements>
              <InjectedCheckoutForm
                plan={this.props.plan}
                sourceId={this.props.sourceId}
                stateClass={this.props.checkoutFormState}
                handleSubmit={this.subscribe}
                handleClose={this.handleCloseCheckoutModal}
                errorMessage={this.props.errorMessage}
                paymentInfo={this.props.updatePaymentMethod.paymentInfo}
              />
            </Elements>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.props.updatePaymentMethod.isModalOpen}
          toggle={this.handleCloseUpdatePaymentMethodModal}
        >
          <ModalBody>
            <Elements>
              <InjectedPaymentMethodForm
                stateClass={this.props.checkoutFormState}
                handleSubmit={this.updatePaymentMethod}
                handleClose={this.handleCloseUpdatePaymentMethodModal}
                errorMessage={this.props.errorMessage}
                paymentInfo={this.props.updatePaymentMethod.paymentInfo}
              />
            </Elements>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.showCancelFloProtectModal}
          toggle={this.handleCloseCancelFloProtectModal}
        >
          <ModalBody className="cancel-subscription-modal">
            <Elements>
              <InjectedCancelSubscription
                stateClass={this.props.checkoutFormState}
                handleSubmit={this.handleCancelSubscription}
                handleClose={this.handleCloseCancelFloProtectModal}
                errorMessage={this.props.errorMessage}
                cancelAtPeriodEnd={this.props.cancelAtPeriodEnd}
                status={this.props.status}
              />
            </Elements>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

FloProtect.contextTypes = {
  mixpanel: PropTypes.object.isRequired
};


export default connect( state => ({
    floProtect: {
      enabled: state.subscription.isSubscribedToFloProtect
    },
    user: {
      ...state.user
    },
    checkoutFormState: state.subscription.checkoutFormState,
    subscriptionId: state.subscription.id,
    errorMessage: state.subscription.errorMessage,
    plan: state.subscription.plan,
    sourceId: state.subscription.sourceId,
    couponInfo: state.subscription.couponInfo,
    updatePaymentMethod: state.subscription.updatePaymentMethod,
    cancelAtPeriodEnd: state.subscription.cancelAtPeriodEnd,
    currentPeriodEnd: state.subscription.currentPeriodEnd,
    status: state.subscription.status,
  })
)( FloProtect );
